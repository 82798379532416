/**@type import("next-i18n-router/dist/types").Config */
export const i18nConfig = {
  locales: ["en", "es"],
  defaultLocale: "en",
  //noPrefix: true,
  //serverSetCookie: "never",
};

export function getDelocalizedPathname(pathname) {
  const localeInPathname = i18nConfig.locales.find(
    (locale) => pathname.startsWith(`/${locale}/`) || pathname === `/${locale}`,
  );
  if (!localeInPathname) return pathname;
  if (pathname === `/${localeInPathname}`) {
    return pathname.replace(`/${localeInPathname}`, "/");
  }
  return pathname?.replace(`/${localeInPathname}`, "");
}
