import { getPageType } from "@/lib/utils/functions";
import { updateStoreWindowSet } from "../store/reducers/misc";
import { constants } from "@/lib/utils/constants";
import Cookies from "js-cookie";

export const setContext = (data, dispatch, retries = 5, interval = 500) => {
  if (!window.magentoStorefrontEvents) {
    if (retries <= 0) {
      console.warn("Magento Storefront Events not found after multiple attempts. Events will not be tracked.");
      return;
    }
    setTimeout(() => {
      setContext(data, dispatch, retries - 1, interval);
    }, interval);
    return;
  }
  try {

    window.magentoStorefrontEvents.context.setStorefrontInstance({
      environmentId: data?.dataServicesStorefrontInstanceContext?.environment_id,
      // instanceId: data?.dataServicesStorefrontInstanceContext?.instance_id,
      environment: data?.dataServicesStorefrontInstanceContext?.environment,
      storeUrl: data?.dataServicesStorefrontInstanceContext?.store_url,
      websiteId: data?.dataServicesStorefrontInstanceContext?.website_id,
      websiteCode: data?.dataServicesStorefrontInstanceContext?.website_code,
      storeId: data?.dataServicesStorefrontInstanceContext?.store_id,
      storeCode: data?.dataServicesStorefrontInstanceContext?.store_code,
      storeViewId: data?.dataServicesStorefrontInstanceContext?.store_view_id,
      storeViewCode: data?.dataServicesStorefrontInstanceContext?.store_view_code,
      websiteName: data?.dataServicesStorefrontInstanceContext?.website_name,
      storeName: data?.dataServicesStorefrontInstanceContext?.store_name,
      storeViewName: data?.dataServicesStorefrontInstanceContext?.store_view_name,
      baseCurrencyCode: data?.dataServicesStorefrontInstanceContext?.base_currency_code,
      storeViewCurrencyCode: data?.dataServicesStorefrontInstanceContext?.store_view_currency_code,
      storefrontTemplate: "GSF",
    });
    window.magentoStorefrontEvents.context.setSearchExtension({
      version: "4.2.2"
    });
    window.magentoStorefrontEvents.context.setMagentoExtension({
      magentoExtensionVersion: data?.dataServicesMagentoExtensionContext?.magento_extension_version
    });
    window.magentoStorefrontEvents.context.setDataServicesExtension({
      version: "7.6.1"
    });
    window.magentoStorefrontEvents.context.setChannel({
      _id: "https://ns.adobe.com/xdm/channels/web",
      _type: "https://ns.adobe.com/xdm/channel-types/web"
    });
    dispatch(updateStoreWindowSet(true));
  } catch (error) {
    console.error("Error initializing MSE:", error);
  }
};

export const setPageContext = () => {
  window.magentoStorefrontEvents.context.setPage({ pageType: getPageType() }
  );
};

export const firePlaceOrderEvent = (payload) => {
  if (!window.magentoStorefrontEvents) return;
  setPageContext();
  window.magentoStorefrontEvents.context.setShoppingCart(payload.shoppingCartCtx);
  window.magentoStorefrontEvents.context.setOrder(payload.orderCtx);
  window.magentoStorefrontEvents.context.setShopper({
    shopperId: Cookies.get(constants.cookies.customerToken) ? "logged-in" : "guest"
  });
  window.magentoStorefrontEvents.publish.placeOrder();
};


export const fireProductViewEvent = async (payload) => {
  if (!window.magentoStorefrontEvents) return;
  setPageContext();
  window.magentoStorefrontEvents.context.setProduct(payload);
  window.magentoStorefrontEvents.context.setShopper({
    shopperId: Cookies.get(constants.cookies.customerToken) ? "logged-in" : "guest"
  });
  window.magentoStorefrontEvents.publish.productPageView();
};

export const fireAddToCartEvent = async (payload) => {
  if (!window.magentoStorefrontEvents) return;
  setPageContext();
  window.magentoStorefrontEvents.context.setProduct(payload.productCtx);
  window.magentoStorefrontEvents.context.setShoppingCart(payload.shoppingCartCtx);
  window.magentoStorefrontEvents.context.setChangedProducts(payload.changedProductsCtx);
  window.magentoStorefrontEvents.context.setShopper({
    shopperId: Cookies.get(constants.cookies.customerToken) ? "logged-in" : "guest"
  });
  window.magentoStorefrontEvents.publish.addToCart();
};

export const fireSearchRequestSentEvent = async (payload) => {
  if (payload?.units?.[0]?.searchRequestId && !window.magentoStorefrontEvents) return;
  window.magentoStorefrontEvents.context.setSearchResults(null);
  setPageContext();
  window.magentoStorefrontEvents.context.setSearchInput(payload);
  window.magentoStorefrontEvents.context.setShopper({
    shopperId: Cookies.get(constants.cookies.customerToken) ? "logged-in" : "guest"
  });
  window.magentoStorefrontEvents.publish.searchRequestSent("desktop-search");
};

export const fireSearchResponseReceivedEvent = async (payload) => {
  if (payload?.mockSearchResults?.units?.[0]?.searchRequestId && !window.magentoStorefrontEvents) return;
  setPageContext();
  window.magentoStorefrontEvents.context.setSearchInput(payload.inputSearchContext)
  window.magentoStorefrontEvents.context.setSearchResults(payload.mockSearchResults);
  window.magentoStorefrontEvents.context.setShopper({
    shopperId: Cookies.get(constants.cookies.customerToken) ? "logged-in" : "guest"
  });
  window.magentoStorefrontEvents.publish.searchResponseReceived("desktop-search");
};

export const fireSearchProductClickEvent = async (payload, sku, inputSearchContext) => {
  if (payload?.units?.[0]?.searchRequestId && !window.magentoStorefrontEvents) return;
  setPageContext();
  window.magentoStorefrontEvents.context.setSearchInput(inputSearchContext)
  window.magentoStorefrontEvents.context.setSearchResults(payload);
  window.magentoStorefrontEvents.context.setShopper({
    shopperId: Cookies.get(constants.cookies.customerToken) ? "logged-in" : "guest"
  });
  window.magentoStorefrontEvents.publish.searchProductClick("desktop-search", sku);
};

export const fireSearchCategoryClickEvent = async (payload, categoryId, inputSearchContext) => {
  if (payload?.units?.[0]?.searchRequestId && !window.magentoStorefrontEvents) return;
  setPageContext();
  window.magentoStorefrontEvents.context.setSearchInput(inputSearchContext)
  window.magentoStorefrontEvents.context.setSearchResults(payload);
  window.magentoStorefrontEvents.context.setShopper({
    shopperId: Cookies.get(constants.cookies.customerToken) ? "logged-in" : "guest"
  });
  window.magentoStorefrontEvents.publish.searchCategoryClick("desktop-search", { categoryId: categoryId });
};

export const fireSearchResultViewEvent = async (payload) => {
  if (payload?.mockSearchResults?.units?.[0]?.searchRequestId && !window.magentoStorefrontEvents) return;
  setPageContext();
  window.magentoStorefrontEvents.context.setSearchInput(payload.inputSearchContext)
  window.magentoStorefrontEvents.context.setSearchResults(payload.mockSearchResults);
  window.magentoStorefrontEvents.context.setShopper({
    shopperId: Cookies.get(constants.cookies.customerToken) ? "logged-in" : "guest"
  });
  window.magentoStorefrontEvents.publish.searchResultsView("desktop-search");
};

export const firePageViewEvent = async () => {
  if (!window.magentoStorefrontEvents) return;
  setPageContext();
  window.magentoStorefrontEvents.context.setShopper({
    shopperId: Cookies.get(constants.cookies.customerToken) ? "logged-in" : "guest"
  });
  window.magentoStorefrontEvents.publish.pageView();
};

export const fireInitiateCheckoutEvent = async (payload) => {
  if (!window.magentoStorefrontEvents) return;
  setPageContext();
  window.magentoStorefrontEvents.context.setShoppingCart(payload);
  window.magentoStorefrontEvents.context.setShopper({
    shopperId: Cookies.get(constants.cookies.customerToken) ? "logged-in" : "guest"
  });
  window.magentoStorefrontEvents.publish.initiateCheckout();
};

export const fireShoppingCartViewEvent = async (payload) => {
  if (!window.magentoStorefrontEvents) return;
  setPageContext();
  window.magentoStorefrontEvents.context.setShoppingCart(payload);
  window.magentoStorefrontEvents.context.setShopper({
    shopperId: Cookies.get(constants.cookies.customerToken) ? "logged-in" : "guest"
  });
  window.magentoStorefrontEvents.publish.shoppingCartView();
};

export const fireOpenCartViewEvent = async (payload) => {
  if (!window.magentoStorefrontEvents) return;
  setPageContext();
  window.magentoStorefrontEvents.context.setShoppingCart(payload);
  window.magentoStorefrontEvents.context.setShopper({
    shopperId: Cookies.get(constants.cookies.customerToken) ? "logged-in" : "guest"
  });
  window.magentoStorefrontEvents.publish.openCart();
};
