import moment from "moment";
import crypto from "browserify-cipher";
import { handleErrorLog } from "./error_logs";

/**
 *  Init vector and secret key can be generated as follows:
 *  const INIT_VECTOR = crypto.randomBytes(16).toString('hex');
    const SECRET_KEY = crypto.randomBytes(16).toString('hex'); 
 */
export const encrypt = async (plainText) => {
  //browserify-cipher is used as Crypto is a node api
  //Buffer from package 'buffer' is used as Buffer is a node api

  const cipher = crypto.createCipheriv(
    process.env.NEXT_PUBLIC_ALGORITHM,
    process.env.NEXT_PUBLIC_SECRET_KEY,
    Buffer.from(process.env.NEXT_PUBLIC_INIT_VECTOR, "hex"),
  );

  const encrypted = Buffer.concat([cipher.update(plainText), cipher.final()]);
  return encrypted.toString("hex");
};

export const decrypt = async (cipherText) => {
  //browserify-cipher is used as Crypto is a node api

  const decipher = crypto.createDecipheriv(
    process.env.NEXT_PUBLIC_ALGORITHM,
    process.env.NEXT_PUBLIC_SECRET_KEY,
    Buffer.from(process.env.NEXT_PUBLIC_INIT_VECTOR, "hex"),
  );

  const decrpyted = Buffer.concat([
    decipher.update(Buffer.from(cipherText, "hex")),
    decipher.final(),
  ]);
  return decrpyted.toString();
};

export const getEncryptedAPIKey = async () => {
  const apiKey = process.env.NEXT_PUBLIC_API_SECRET;
  const utcTimestamp = moment.utc().valueOf(); // UTC unix timestamp is in milliseconds
  const expiryTime = utcTimestamp + 2 * 60 * 1000;
  return await encrypt(`${expiryTime}GSF${apiKey}`);
};

export const isValidAPIKey = async (cipherText) => {
  if (cipherText) {
    try {
      const decryptedText = await decrypt(cipherText);
      const [exiryTime, keyCode] = decryptedText.split("GSF");
      if ((exiryTime, keyCode)) {
        const utcTimestamp = moment.utc().valueOf();
        if (
          Number(exiryTime) > utcTimestamp &&
          keyCode === process.env.NEXT_PUBLIC_API_SECRET
        ) {
          return true;
        }
      }
    } catch (err) {
      handleErrorLog({
        error: err,
        additional_info: { cipherText },
        msg: "Error while checking API Key",
      });
    }
  }
  return false;
};
