import axios from "axios";
import { keys } from "../utils/keys";

export const getCMSPageMeta = async (identifier) => {
  const query = `
    {
      cmsPage(identifier: "${identifier}") {
        title
        meta_title
        meta_description
        meta_keywords
      }
    }`;

  const axiosRes = await axios.get(keys.general.graphqlUrl, {
    params: { query },
    paramsSerializer: { indexes: null },
  });

  return axiosRes.data;
};

export const getBlogPageMeta = async (url_key) => {
  const axiosRes = await axios.post(keys.general.graphqlUrl, {
    query: `query GetBlogPost($url_key: String!) {
      blogPostByUrlKey(url_key: $url_key) {
        title
        meta_keywords
        meta_description
      }
    }`,
    variables: { url_key },
  });
  return axiosRes.data;
};

export const getCmsPage = async (pageId) => {
  const query = `
    {
      cmsPage(id: "${pageId}") {
        identifier
        url_key
        title
        content
        content_heading
        page_layout
        meta_title
        meta_description
        meta_keywords
      }
    }`;

  const axiosRes = await axios.get(keys.general.graphqlUrl, {
    params: { query },
    paramsSerializer: { indexes: null },
  });

  return axiosRes.data;
};

// Fetch Blog Post List with pagination
export const getBlogPostList = async (page_size = 10, page = 1) => {
  const axiosRes = await axios.post(keys.general.graphqlUrl, {
    query: `
      query GetBlogPostList($page: Int, $page_size: Int) {
        blogPostList(page: $page, page_size: $page_size) {
          items {
            id
            title
            url_key
            short_content
            status
            created_at
            updated_at
            publish_date
          }
          total_count
          current_page
          page_size
        }
      }
    `,
    variables: {
      page,
      page_size,
    },
  });
  return axiosRes.data;
};

export const getBlogPostByUrlKey = async (url_key) => {
  const axiosRes = await axios.post(keys.general.graphqlUrl, {
    query: `
        query GetBlogPost($url_key: String!) {
          blogPostByUrlKey(url_key: $url_key) {
            id
            title
            url_key
            short_content
            content
            author_id
            status
            created_at
            updated_at
            publish_date
            is_allow_comments
            meta_keywords
            meta_description
            customer_groups
            is_featured
            author_display_mode
            are_related_products_enabled
            is_author_badge_enabled
            category_id
            store_id
          }
        }
      `,
    variables: {
      url_key, // Passing the url_key dynamically
    },
  });
  return axiosRes.data;
};
