import React from "react";
import * as htmlEntity from "he";
import { handleErrorLog } from "./error_logs";

export const isSSR = () => typeof window === "undefined";

export const removeCartFromStorage = (key) => {
  localStorage.removeItem(key);
};

export const isEmptyObject = (obj) => {
  if (obj === null || obj === undefined) return true;
  if (typeof obj !== "object") return false;
  return Object.keys(obj).length === 0;
};

export const scrollToElement = (elementId, margin = 140, delay = 250) => {
  if (!isSSR()) {
    setTimeout(() => {
      const element = document.getElementById(elementId);
      if (element) {
        const y = element.getBoundingClientRect().top + window.scrollY;
        window.scroll({
          top: y - margin,
          behavior: "smooth",
        });
      }
    }, delay);
  }
};

export const renderHTML = (rawHTML) => {
  if (!rawHTML) return null;
  return React.createElement("div", {
    dangerouslySetInnerHTML: { __html: htmlEntity.decode(rawHTML) },
  });
};

export const replaceHistoryState = ({ data = null, unused = "", url = "" }) => {
  const isServer = typeof window === "undefined";
  if (!isServer) {
    window.history.replaceState(data, unused, url || location.pathname);
  }
};

export const updateRecentlyViewed = (productSKU, storageKeys) => {
  let recentItems = [];
  const itemString = localStorage.getItem(storageKeys.recently_viewed);

  try {
    recentItems = itemString ? JSON.parse(itemString) : [];
  } catch (error) {
    recentItems = [];
    handleErrorLog({
      error,
      additional_info: { productSKU },
      msg: "Error while parsing recently products",
    });
  }

  if (productSKU && recentItems.includes(productSKU)) {
    const skuIndex = recentItems.indexOf(productSKU);
    recentItems.splice(skuIndex, 1);
  }

  recentItems = [productSKU, ...recentItems];
  if (recentItems.length > 6) recentItems.pop();

  localStorage.setItem(
    storageKeys.recently_viewed,
    JSON.stringify(recentItems),
  );
};
