const commonDesc =
  "GSF Car Parts stocks all of the car parts, accessories, and tools that you’ll need to keep your vehicle in good shape. Free UK delivery. Shop online today.";

export const staticMeta = {
  title:
    "Car Parts & Spares – Buy Online with Fast UK Delivery | GSF Car Parts",
  description: commonDesc,
  keywords: [
    "car battery",
    "gsf car parts",
    "catalytic converter",
    "ford parts",
    "car mats",
    "oil",
    "exhaust",
    "wiper blades",
    "bmw parts",
    "clutch",
    "vw parts",
    "brake pads",
    "windscreen wipers",
  ],
  robots: {
    follow: false,
    index: false,
    googleBot: {
      follow: false,
      index: false,
    },
  },
};

export const metaInfo = {
  makeandmodels: {
    archive: {
      meta_title:
        "A - Z List of all the Makes & Models We Stock | GSF Car Parts",
      meta_description:
        "Shop car parts for a wide range of car makes & models. Check out our full list of available car parts Buy online or in-store with free nationwide delivery over £25.",
    },
    make_selected: {
      meta_title: "{{ MAKE }} Spare Parts | GSF Car Parts",
      meta_description:
        "Shop car parts for a wide range for {{ MAKE }}. Check out our full list of available car parts. Buy online or in-store with free nationwide delivery over £25.",
    },
    make_and_model_selected: {
      meta_title: "{{ MAKE }} {{ MODEL }} Car Parts | GSF Car Parts",
      meta_description:
        "Shop {{ MAKE }} {{ MODEL }} Car Parts from top brands at competitive prices. Buy online or in-store with free nationwide delivery over £25",
    },
  },
  branches: {
    meta_title:
      "Car Parts & Spares – Buy Online with Fast UK Delivery | GSF Car Parts | Branches",
    meta_description: commonDesc,
    meta_keywords:
      "car battery, gsf car parts, catalytic converter, ford parts, car mats, oil, exhaust, wiper blades, bmw parts, clutch, vw parts, brake pads, windscreen wipers",
  },
  branch: {
    initial_meta_title:
      "GSF Car Parts {{ LOCATION }} | Car Parts, Spares and Accessories",
    meta_title:
      "GSF Car Parts {{ LOCATION }} | Car Parts, Spares and Accessories",
    meta_description:
      "Visit the GSF Car Parts {{ LOCATION }} branch for car parts, spares and accessories. Find the store's opening hours, address, and phone number, available for in-store shopping or click-and-collect..",
    meta_keywords: "branches, stores",
  },
  search_result: {
    meta_title: "Search Result = {{ QUERY }} | GSF Car Parts",
    meta_description: commonDesc,
  },
  brand: {
    meta_title: "Shop {{ BRAND }} Car Parts | GSF Car Parts",
    meta_description:
      "Shop car parts from {{ BRAND }}. We Stock 1000s of the top manufacturer parts to keep your vehicle running. Buy online or in-store with free nationwide delivery over £25",
  },
  supplier: {
    meta_title: "Shop By Brand  - A - Z List of Our Suppliers | GSF Car Parts",
    meta_description:
      "With over 100,000 products stocked by GSF Car Parts, we sell parts from the top brands across the industry. Shop our full catalogue of car parts.",
  },
  product: {
    meta_title: "{{ TITLE }} | GSF Car Parts",
    meta_description:
      "Shop {{ TITLE }} from GSF. We have everything you need for your vehicle. Enjoy fast, free UK delivery over £25 or pick up in-store at one of our nationwide branches.",
  },
  category: {
    meta_title: "{{ CATEGORY }} | GSF Car Parts",
    meta_description:
      "Shop our range of {{ CATEGORY }} from top brands at competitive prices. Use our car reg lookup to find the right {{ CATEGORY }} for your vehicle quickly. Buy online or in-store with free nationwide delivery over £25",
  },
  misc: {
    meta_title: "{{ HEADING }} | GSF Car Parts",
    meta_description: commonDesc,
  },
};
