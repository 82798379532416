import Image from "next/image";
import Link from "next/link";
const ContentfulAsset = ({ node, renderEmbeddedAsset }) => {
  //Default rendering
  const {
    title = "",
    description = "",
    file = {},
  } = node?.data?.target?.fields || {};
  const imageUrl = file.url || "";
  const { width = "", height = "" } = file?.details?.image || {};
  const imageDescription = description?.["en-US"] ? description["en-US"] : "";

  let defaultUI;

  if (file?.contentType?.includes?.("image")) {
    defaultUI = (
      <Image
        className="my-3"
        style={{
          height: "auto",
          width: `min(100%, ${width}px)`,
          objectFit: "contain",
        }}
        src={`https:${imageUrl}`}
        alt={title || description}
        title={imageDescription}
        width={width}
        height={height}
        quality={70}
      />
    );
  } else {
    defaultUI = (
      <p>
        <Link
          prefetch={false}
          href={`https:${file.url}`}
          className="my-3"
          rel="noopener"
          target="_blank"
        >
          {title}
        </Link>
      </p>
    );
  }

  if (typeof renderEmbeddedAsset === "function") {
    return renderEmbeddedAsset(
      file?.contentType,
      node?.data?.target?.fields,
      defaultUI,
    );
  }

  return defaultUI;
};

export default ContentfulAsset;
