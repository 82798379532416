import { createSlice } from "@reduxjs/toolkit";

const miniTrolley = createSlice({
  name: "miniTrolley",
  initialState: {
    miniTrolley: false,
  },
  reducers: {
    setMiniTrolley(state, action) {
      state.miniTrolley = action.payload;
    },
  },
});

export const { setMiniTrolley } = miniTrolley.actions;
export default miniTrolley;
