import * as yup from "yup";
import { messages } from "./messages";
import { constants } from "./constants";

export const isValidInput = (text) => {
  // Return true if field is empty (optional field)
  if (!text) return true;
  // Validate against special characters
  let regex = new RegExp(/^[\w-_.!@#£$%^&+*()?/, '".\n]*$/);
  return regex.test(text);
};

const isValidEmail = (text) => {
  const regex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
  return regex.test(text);
};

export const SignupSchema = (t) =>
  yup.object({
    firstName: yup
      .string()
      .required(t("common:messages.error.requiredField"))
      .trim()
      .test(
        "is-valid-input",
        t("common:messages.error.invalidInput"),
        (value) => isValidInput(value),
      ),
    lastName: yup
      .string()
      .required(t("common:messages.error.requiredField"))
      .trim()
      .test(
        "is-valid-input",
        t("common:messages.error.invalidInput"),
        (value) => isValidInput(value),
      ),
    email: yup
      .string()
      .email(t("common:messages.error.invalidEmail"))
      .required(t("common:messages.error.requiredField"))
      .trim()
      .test(
        "is-valid-input",
        t("common:messages.error.invalidInput"),
        (value) => isValidInput(value),
      )
      .test(
        "is-valid-input",
        t("common:messages.error.invalidEmail"),
        (value) => isValidEmail(value),
      ),
    password: yup
      .string()
      .required(t("common:messages.error.requiredField"))
      .trim()
      .min(8, t("common:messages.error.passwordMinLength"))
      .matches(constants.regex.password, {
        message: t("common:messages.error.passwordCriterion"),
      })
      .test(
        "is-valid-input",
        t("common:messages.error.invalidInput"),
        (value) => isValidInput(value),
      ),
    confirmPassword: yup
      .string()
      .required(t("common:messages.error.requiredField"))
      .trim()
      .oneOf(
        [yup.ref("password"), ""],
        t("common:messages.error.passwordUnmatch"),
      )
      .test(
        "is-valid-input",
        t("common:messages.error.invalidInput"),
        (value) => isValidInput(value),
      ),
    newsletter: yup.boolean(),
  });

export const SigninSchema = (t) =>
  yup.object({
    email: yup
      .string()
      .email(t("common:messages.error.invalidEmail"))
      .required(t("common:messages.error.requiredField"))
      .trim()
      .test(
        "is-valid-input",
        t("common:messages.error.invalidInput"),
        (value) => isValidInput(value),
      )
      .test(
        "is-valid-input",
        t("common:messages.error.invalidEmail"),
        (value) => isValidEmail(value),
      ),
    password: yup
      .string()
      .required(t("common:messages.error.requiredField"))
      .trim()
      .test(
        "is-valid-input",
        t("common:messages.error.invalidInput"),
        (value) => isValidInput(value),
      ),
  });

export const CheckEmailSchema = (t) =>
  yup.object({
    email: yup
      .string()
      .email(t("common:messages.error.invalidEmail"))
      .required(t("common:messages.error.requiredField"))
      .trim()
      .test(
        "is-valid-input",
        t("common:messages.error.invalidInput"),
        (value) => isValidInput(value),
      )
      .test(
        "is-valid-input",
        t("common:messages.error.invalidEmail"),
        (value) => isValidEmail(value),
      ),
  });

export const ForgotPasswordSchema = yup.object({
  email: yup
    .string()
    .email(messages.error.invalidEmail)
    .required(messages.error.requiredField)
    .trim()
    .test("is-valid-input", messages.error.invalidInput, (value) =>
      isValidInput(value),
    )
    .test("is-valid-input", messages.error.invalidEmail, (value) =>
      isValidEmail(value),
    ),
});

export const CreatePasswordSchema = yup.object({
  password: yup
    .string()
    .required(messages.error.requiredField)
    .trim()
    .min(8, messages.error.passwordMinLength)
    .matches(constants.regex.password, {
      message: messages.error.passwordCriterion,
    })
    .test("is-valid-input", messages.error.invalidInput, (value) =>
      isValidInput(value),
    ),

  confirmPassword: yup
    .string()
    .required(messages.error.requiredField)
    .trim()
    .oneOf([yup.ref("password"), ""], messages.error.passwordUnmatch)
    .test("is-valid-input", messages.error.invalidInput, (value) =>
      isValidInput(value),
    ),
});

export const ConatctSchema = yup.object({
  reason: yup
    .string()
    .required(messages.error.requiredField)
    .test("is-valid-input", messages.error.invalidInput, (value) =>
      isValidInput(value),
    ),
  firstName: yup
    .string()
    .required(messages.error.requiredField)
    .trim()
    .test("is-valid-input", messages.error.invalidInput, (value) =>
      isValidInput(value),
    ),
  lastName: yup
    .string()
    .required(messages.error.requiredField)
    .trim()
    .test("is-valid-input", messages.error.invalidInput, (value) =>
      isValidInput(value),
    ),
  email: yup
    .string()
    .email(messages.error.invalidEmail)
    .required(messages.error.requiredField)
    .trim()
    .test("is-valid-input", messages.error.invalidInput, (value) =>
      isValidInput(value),
    )
    .test("is-valid-input", messages.error.invalidEmail, (value) =>
      isValidEmail(value),
    ),
  vehicle_registration: yup
    .string()
    .trim()
    .uppercase()
    .test("is-valid-input", messages.error.invalidInput, (value) =>
      isValidInput(value),
    ),
  telephone: yup
    .string()
    .trim()
    .matches(constants.regex.phone, {
      message: messages.error.invalidPhone,
      excludeEmptyString: true,
    })
    .max(14, messages.error.maxPhoneLength)
    .test("is-valid-input", messages.error.invalidInput, (value) =>
      isValidInput(value),
    ),
  order_number: yup
    .string()
    .trim()
    .test("is-valid-input", messages.error.invalidInput, (value) =>
      isValidInput(value),
    ),
  comment: yup
    .string()
    .required(messages.error.requiredField)
    .trim()
    .test("is-valid-input", messages.error.invalidInput, (value) =>
      isValidInput(value),
    ),
});

export const NewsletterSchema = yup.object({
  email: yup
    .string()
    .email(messages.error.invalidEmail)
    .required(messages.error.requiredField)
    .trim()
    .test("is-valid-input", messages.error.invalidInput, (value) =>
      isValidInput(value),
    )
    .test("is-valid-input", messages.error.invalidEmail, (value) =>
      isValidEmail(value),
    ),
});
export const notifyStockAlertGuest = (t) =>
  yup.object({
    email: yup
      .string()
      .email(t("common:messages.error.invalidEmail"))
      .required(t("common:messages.error.requiredField"))
      .trim()
      .test(
        "is-valid-input",
        t("common:messages.error.invalidEmail"),
        (value) => isValidEmail(value),
      ),
    subscribe: yup.boolean(),
  });

export const addressBookSchema = (t) =>
  yup.object({
    firstname: yup
      .string()
      .required(t("common:messages.error.requiredField"))
      .trim()
      .test("is-valid-input", messages.error.invalidInput, (value) =>
        isValidInput(value),
      ),
    lastname: yup
      .string()
      .required(t("common:messages.error.requiredField"))
      .trim()
      .test("is-valid-input", messages.error.invalidInput, (value) =>
        isValidInput(value),
      ),
    telephone: yup
      .string()
      .matches(constants.regex.phone, {
        message: t("common:messages.error.invalidPhone"),
        excludeEmptyString: true,
      })
      .required(t("common:messages.error.requiredField"))
      .min(10, t("common:messages.error.invalidPhone"))
      .max(12, t("common:messages.error.invalidPhone"))
      .trim()
      .test("is-valid-input", messages.error.invalidInput, (value) =>
        isValidInput(value),
      ),
    company: yup
      .string()
      .trim()
      .nullable()
      .test("is-valid-input", messages.error.invalidInput, (value) =>
        isValidInput(value),
      ),
    street_main: yup
      .string()
      .required(t("common:messages.error.requiredField"))
      .test("is-valid-input", messages.error.invalidInput, (value) =>
        isValidInput(value),
      ),
    street: yup
      .array()
      .required(t("common:messages.error.requiredField"))
      .test("is-valid-input", messages.error.invalidInput, (value) =>
        isValidInput(value),
      ),
    city: yup
      .string()
      .required(t("common:messages.error.requiredField"))
      .trim()
      .test("is-valid-input", messages.error.invalidInput, (value) =>
        isValidInput(value),
      ),
    postcode: yup
      .string()
      .required(t("common:messages.error.requiredField"))
      .trim()
      .test("is-valid-input", messages.error.invalidInput, (value) =>
        isValidInput(value),
      ),
  });

export const checkoutFormSchema = (t) =>
  yup.object({
    is_same_billing: yup.boolean(),
    shipping: yup.object({
      firstname: yup
        .string()
        .required(t("common:messages.error.requiredField"))
        .trim()
        .test("is-valid-input", messages.error.invalidInput, (value) =>
          isValidInput(value),
        ),
      lastname: yup
        .string()
        .required(t("common:messages.error.requiredField"))
        .trim()
        .test("is-valid-input", messages.error.invalidInput, (value) =>
          isValidInput(value),
        ),
      telephone: yup
        .string()
        .matches(constants.regex.phone, {
          message: t("common:messages.error.invalidPhone"),
          excludeEmptyString: true,
        })
        .required(t("common:messages.error.requiredField"))
        .min(10, t("common:messages.error.invalidPhone"))
        .max(12, t("common:messages.error.invalidPhone"))
        .trim()
        .test("is-valid-input", messages.error.invalidInput, (value) =>
          isValidInput(value),
        ),
      company: yup
        .string()
        .trim()
        .nullable()
        .test("is-valid-input", messages.error.invalidInput, (value) =>
          isValidInput(value),
        ),
      street_main: yup
        .string()
        .required(t("common:messages.error.requiredField"))
        .test("is-valid-input", messages.error.invalidInput, (value) =>
          isValidInput(value),
        ),
      street: yup
        .array()
        .required(t("common:messages.error.requiredField"))
        .test("is-valid-input", messages.error.invalidInput, (value) =>
          isValidInput(value),
        ),
      city: yup
        .string()
        .required(t("common:messages.error.requiredField"))
        .trim()
        .test("is-valid-input", messages.error.invalidInput, (value) =>
          isValidInput(value),
        ),
      postcode: yup
        .string()
        .required(t("common:messages.error.requiredField"))
        .trim()
        .test("is-valid-input", messages.error.invalidInput, (value) =>
          isValidInput(value),
        ),
    }),
    billing: yup.object({
      firstname: yup
        .string()
        .required(t("common:messages.error.requiredField"))
        .trim()
        .test("is-valid-input", messages.error.invalidInput, (value) =>
          isValidInput(value),
        ),
      lastname: yup
        .string()
        .required(t("common:messages.error.requiredField"))
        .trim()
        .test("is-valid-input", messages.error.invalidInput, (value) =>
          isValidInput(value),
        ),
      telephone: yup
        .string()
        .matches(constants.regex.phone, {
          message: t("common:messages.error.invalidPhone"),
          excludeEmptyString: true,
        })
        .required(t("common:messages.error.requiredField"))
        .min(10, t("common:messages.error.invalidPhone"))
        .max(12, t("common:messages.error.invalidPhone"))
        .trim()
        .test("is-valid-input", messages.error.invalidInput, (value) =>
          isValidInput(value),
        ),
      company: yup
        .string()
        .trim()
        .nullable()
        .test("is-valid-input", messages.error.invalidInput, (value) =>
          isValidInput(value),
        ),
      street_main: yup
        .string()
        .required(t("common:messages.error.requiredField"))
        .test("is-valid-input", messages.error.invalidInput, (value) =>
          isValidInput(value),
        ),
      street: yup
        .array()
        .required(t("common:messages.error.requiredField"))
        .test("is-valid-input", messages.error.invalidInput, (value) =>
          isValidInput(value),
        ),
      city: yup
        .string()
        .required(t("common:messages.error.requiredField"))
        .trim()
        .test("is-valid-input", messages.error.invalidInput, (value) =>
          isValidInput(value),
        ),
      postcode: yup
        .string()
        .required(t("common:messages.error.requiredField"))
        .trim()
        .test("is-valid-input", messages.error.invalidInput, (value) =>
          isValidInput(value),
        ),
    }),
  });

export const ChangePasswordSchema = (t) =>
  yup.object({
    oldPassword: yup
      .string()
      .required(t("common:messages.error.requiredField"))
      .trim()
      .min(8, t("common:messages.error.passwordMinLength"))
      .matches(constants.regex.password, {
        message: t("common:messages.error.passwordCriterion"),
      })
      .test("is-valid-input", messages.error.invalidInput, (value) =>
        isValidInput(value),
      ),
    password: yup
      .string()
      .required(t("common:messages.error.requiredField"))
      .trim()
      .min(8, t("common:messages.error.passwordMinLength"))
      .matches(constants.regex.password, {
        message: t("common:messages.error.passwordCriterion"),
      })
      .test("is-valid-input", messages.error.invalidInput, (value) =>
        isValidInput(value),
      ),
    confirmPassword: yup
      .string()
      .required(t("common:messages.error.requiredField"))
      .trim()
      .oneOf(
        [yup.ref("password"), ""],
        t("common:messages.error.newPasswordUnmatch"),
      )
      .test("is-valid-input", messages.error.invalidInput, (value) =>
        isValidInput(value),
      ),
  });

export const basketBranchSearchSchema = (t) =>
  yup.object({
    postCode: yup
      .string()
      .required("This is a required field")
      .trim()
      .test("is-valid-input", messages.error.invalidInput, (value) =>
        isValidInput(value),
      ),
  });

export const checkVehicleSchema = () =>
  yup.object({
    reg: yup
      .string()
      .trim()
      .test("is-valid-input", messages.error.invalidInput, (value) =>
        isValidInput(value),
      ),
    make: yup
      .string()
      .trim()
      .test("is-valid-input", messages.error.invalidInput, (value) =>
        isValidInput(value),
      ),
    model: yup
      .string()
      .trim()
      .test("is-valid-input", messages.error.invalidInput, (value) =>
        isValidInput(value),
      ),
    type: yup.string(),
    year: yup
      .string()
      .trim()
      .test("is-valid-input", messages.error.invalidInput, (value) =>
        isValidInput(value),
      ),
    body: yup
      .string()
      .trim()
      .test("is-valid-input", messages.error.invalidInput, (value) =>
        isValidInput(value),
      ),
    kw_hp: yup
      .string()
      .trim()
      .test("is-valid-input", messages.error.invalidInput, (value) =>
        isValidInput(value),
      ),
  });

export const confirmReservationSchema = (t) =>
  yup.object({
    firstname: yup
      .string()
      .required(t("common:messages.error.requiredField"))
      .trim()
      .test("is-valid-input", messages.error.invalidInput, (value) =>
        isValidInput(value),
      ),
    lastname: yup
      .string()
      .required(t("common:messages.error.requiredField"))
      .trim()
      .test("is-valid-input", messages.error.invalidInput, (value) =>
        isValidInput(value),
      ),
    telephone: yup
      .string()
      .trim()
      .matches(constants.regex.phone, {
        message: messages.error.invalidPhone,
        excludeEmptyString: true,
      })
      .max(14, messages.error.maxPhoneLength)
      .required(t("common:messages.error.requiredField"))
      .test("is-valid-input", messages.error.invalidInput, (value) =>
        isValidInput(value),
      ),
    email: yup
      .string()
      .email(t("common:messages.error.invalidEmail"))
      .required(t("common:messages.error.requiredField"))
      .trim()
      .test("is-valid-input", messages.error.invalidInput, (value) =>
        isValidInput(value),
      )
      .test(
        "is-valid-input",
        t("common:messages.error.invalidEmail"),
        (value) => isValidEmail(value),
      ),
  });
