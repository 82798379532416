import moment from "moment-timezone";
import { getGloablAssets } from "@/lib/services/general";
import { handleGraphqlResponse } from "@/lib/utils/functions";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

const initialState = {
  timezone: "",
  tagIcons: null,
  storeConfig: {},
  GSF_telephone: "",
  showCheckStock: true,
  breadcrumbs: [],
  customerGroup: ""
};

export const fetchGlobalAssets = createAsyncThunk(
  "globalAssets/fetchGlobalAssets",
  async () => {
    try {
      const res = await getGloablAssets();
      const data = handleGraphqlResponse(res);
      return data;
    } catch (err) {
      return Promise.reject(err.message);
    }
  },
);

const globalAssets = createSlice({
  name: "globalAssets",
  initialState,
  reducers: {
    updateShowCheckStock(state, action) {
      state.showCheckStock = action.payload;
    },
    updateBreadcrumb(state, action) {
      state.breadcrumbs = action.payload?.breadcrumbs;
    },
    updateCustomerGroup(state, action) {
      state.customerGroup = action.payload
    }
  },
  extraReducers: (builder) => {
    builder.addCase(fetchGlobalAssets.fulfilled, (state, action) => {
      const { storeConfig, ...rest } = action.payload;
      state.tagIcons = rest;
      state.timezone = storeConfig?.timezone || moment.tz.guess();
      state.storeConfig = storeConfig;
      state.GSF_telephone = storeConfig?.contact_mobile_number;
    });
  },
});

export const { updateShowCheckStock, updateBreadcrumb, updateCustomerGroup } = globalAssets.actions;
export const getTimezone = (state) => state.globalAssets.timezone;
export const getGlobalArgs = (state) => state.globalAssets.tagIcons;
export const getStoreConfig = (state) => state.globalAssets.storeConfig;

export default globalAssets;
