export const handleErrorLog = ({
  msg,
  error,
  is_warning = false,
  additional_info = {},
}) => {
  if (is_warning) {
    console.warn(msg, { ...additional_info, error });
  } else {
    console.error(
      ":::::::::::::::::::::::::: " +
        new Date().toISOString() +
        " ::::::::::::::::::::::::::",
    );
    console.error(msg, { ...additional_info, error });
  }
};
