import { constants } from "../utils/constants";
import { handleErrorLog } from "../utils/error_logs";
import { MVLQueryStringToKey } from "../utils/functions";
import { updateVehicleCookies } from "../utils/cookieMethods";
import { getEncryptedAPIKey, isValidAPIKey } from "../utils/crypto";

const getRequestOptions = async (type, payload = {}) => {
  // Build headers
  const headers = {
    accept: "application/json",
    authorization: await getEncryptedAPIKey(),
  };

  // Add reCAPTCHA token if present
  if (payload.recaptchaToken) {
    headers["X-ReCaptcha"] = payload.recaptchaToken;
  }

  // Remove reCAPTCHA token from payload to avoid sending it in the body
  const cleanedPayload = { ...payload };
  delete cleanedPayload.recaptchaToken;

  // Initialize options
  const options = {
    method: type,
    headers,
  };

  // Add specific configurations based on the request type
  switch (type) {
    case "GET":
      //Revalidate after half a day.
      options["next"] = { revalidate: 43200 };
      break;
    case "POST":
      options["body"] = JSON.stringify(cleanedPayload);
      break;
  }

  return options;
};

const sendPostRequest = async (endpoint, payload, resIsArray = false) => {
  try {
    const options = await getRequestOptions("POST", payload);
    const res = await fetch(endpoint, options);
    return await res.json();
  } catch (err) {
    handleErrorLog({
      error: err,
      additional_info: { endpoint, payload },
      msg: "Error while sending post request",
    });
    return resIsArray ? [] : {};
  }
};

export const validateApiKey = async (apiToken) => {
  const isValid = await isValidAPIKey(apiToken);
  if (!isValid) {
    return Response.json(
      {
        message: "Forbidden! You don't have permission to access.",
      },
      { status: 401 },
    );
  }
  return null;
};

export const getProductsStocks = async (payload) => {
  return await sendPostRequest("/api/check-stock", payload, true);
};

export const getVehicleInfo = async (
  queryString,
  updateCookies = false,
  hasManualLook = false,
) => {
  try {
    const options = await getRequestOptions("GET");
    const res = await fetch(`/api/vehicle-info?${queryString}`, options);
    const { data, error } = await res.json();
    if (updateCookies && data) {
      let key = "";
      if (queryString.includes("vrm")) {
        key = queryString.split("=")[1];
      } else {
        key = MVLQueryStringToKey(queryString);
      }
      updateVehicleCookies({ key, hasManualLook });
    }
    return { data, error };
  } catch (err) {
    handleErrorLog({
      error: err,
      additional_info: { queryString },
      msg: "Error while fetching vehicle info",
    });
    return { data: null, error: err?.message };
  }
};

export const getNearestBranches = async (payload) => {
  return await sendPostRequest("/api/nearest-branches", payload);
};

export const signupUser = async (payload) => {
  let res = { data: null, error: null };
  try {
    const options = await getRequestOptions("POST", payload);
    const apiRes = await fetch("/api/signup", options);
    const jsonData = await apiRes.json();
    if (apiRes?.status === 200) {
      res.data = jsonData;
    } else {
      res.error = jsonData.message;
    }
  } catch (err) {
    handleErrorLog({
      error: err,
      additional_info: { payload },
      msg: "Error while signup user",
    });
    res.error = err;
  }

  return res;
};

export const signinUser = async (payload) => {
  return await sendPostRequest("/api/signin", payload);
};

export const changeUserPassword = async (payload) => {
  return await sendPostRequest("/api/change-password", payload);
};

export const createNewPassword = async (payload) => {
  return await sendPostRequest("/api/create-new-password", payload);
};

export const handleVehicleResposne = (t, vRes, handleToastError) => {
  if (!vRes?.data) {
    if (
      [
        constants.service_response.not_found,
        constants.service_response.modal_not_found,
      ].includes(vRes?.error)
    ) {
      handleToastError(t("messages.no_results.vehicle"));
    } else if (vRes?.error === constants.service_response.unavailable) {
      handleToastError(t("messages.service_unavailable"));
    } else {
      handleToastError(vRes?.error);
    }
    return false;
  }
  return vRes?.data;
};
