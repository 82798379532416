"use client";

import Image from "next/image";
import { useEffect, useState } from "react";
import { useAppSelector } from "@/lib/store";
import { constants } from "@/lib/utils/constants";
import brand from "@/assets/static/brand_placeholder.png";
import brandSM from "@/assets/static/brand_placeholder_sm.png";
import badge from "@/assets/static/product_badge_placeholder.png";
import placeholder from "@/assets/static/product_placeholder.png";
import recommendedFallback from "@/assets/static/brand_recommended_placeholder.png";
import { getGlobalArgs } from "@/lib/store/reducers/globalAssets";

const ImageWithFallback = ({
  src,
  alt,
  width,
  height,
  type = "",
  size = "",
  quality = 75,
  ...props
}) => {
  const { bestseller, recommended } = useAppSelector(getGlobalArgs) || {};

  const getDefaultImageSrc = (ignoreSrc = false) => {
    if (!ignoreSrc && src) return src;
    let image = placeholder;
    switch (type) {
      case constants.image_types.brand:
        image = brand;
        if (size === "small") image = brandSM;
        break;
      case constants.image_types.product:
      case constants.image_types.category:
        image = placeholder;
        break;
      case constants.image_types.product_recommended:
      case constants.image_types.product_best_seller:
        image = bestseller || badge;
        break;
      case constants.image_types.brand_recommended:
        image = recommended || recommendedFallback;
        break;
    }
    return image;
  };

  const updateImageSource = () => {
    setImgSrc(getDefaultImageSrc(true));
  };

  const [imgSrc, setImgSrc] = useState(getDefaultImageSrc());

  useEffect(() => {
    if (
      bestseller !== undefined &&
      imgSrc !== bestseller &&
      constants.image_types.product_best_seller === type
    ) {
      setImgSrc(bestseller);
    }
  }, [bestseller]);

  useEffect(() => {
    if (
      recommended !== undefined &&
      imgSrc !== recommended &&
      constants.image_types.product_recommended === type
    ) {
      setImgSrc(recommended);
    }
  }, [recommended]);

  return (
    <Image
      {...props}
      src={imgSrc}
      width={width}
      height={height}
      quality={quality}
      alt={alt || "img"}
      unoptimized={true}
      onError={updateImageSource}
    />
  );
};

export default ImageWithFallback;
