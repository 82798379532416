import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { getVehicleManufacturers } from "@/lib/services/vehicles";

const initialState = {
  makeOptions: [],
};

export const fetchMakeOptions = createAsyncThunk(
  "makeOptions/fetchMakeOptions",
  async () => {
    try {
      return await getVehicleManufacturers();
    } catch (err) {
      return Promise.reject(err.message);
    }
  }
);

const makeOptions = createSlice({
  name: "makeOptions",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchMakeOptions.fulfilled, (state, action) => {
      if (action.payload.length) {
        state.makeOptions = action.payload;
      }
    });
  },
});

export const getMakeOptions = (state) => state.makeOptions.makeOptions;

export default makeOptions;
