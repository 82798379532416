import axios from "axios";
import Cookies from "js-cookie";
import { decrypt } from "./crypto";
import { constants } from "./constants";
import { handleGraphqlResponse } from "./functions";

export const customerAxios = axios.create();

/**
 * @description serverSideToken needs to be get from cookies from server side
 * next/headers module if customer related apis are to be called from server.
 * @param {string} serverSideToken
 * @returns
 */
export const getCustomerAxios = async (userToken = null) => {
  const encryptedToken =
    userToken || Cookies.get(constants.cookies.customerToken);
  if (encryptedToken) {
    const token = await decrypt(encryptedToken);
    customerAxios.defaults.headers.common.Authorization = `Bearer ${token}`;
  } else {
    delete customerAxios.defaults.headers.common.Authorization;
  }
  return customerAxios;
};

export const baseRequest = async (url, query, variables = null) => {
  try {
    let queryPayload = { query };
    if (variables) {
      queryPayload.variables = variables;
    }
    const { data } = await axios.post(url, queryPayload);
    return [handleGraphqlResponse(data), null];
  } catch (err) {
    return [null, { message: err.message }];
  }
};
