import { getVehicleDetails } from "@/lib/services/garage";
import { handleGraphqlResponse } from "@/lib/utils/functions";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

const initialState = {
  garageVehicle: {},
  vehicle: null,
};

export const fetchGarageVehicleDetails = createAsyncThunk(
  "garageVehicle/fetchGarageVehicleDetails",
  async (payload) => {
    if (payload === null) {
      return {};
    }
    try {
      const res = await getVehicleDetails(payload);
      const data = handleGraphqlResponse(res);
      return data;
    } catch (err) {
      return Promise.reject(err.message);
    }
  },
);

const vehicle = createSlice({
  name: "vehicle",
  initialState,
  reducers: {
    updateVehicle(state, action) {
      state.vehicle = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchGarageVehicleDetails.fulfilled, (state, action) => {
      state.garageVehicle = action.payload;
    });
  },
});

export const { updateVehicle } = vehicle.actions;
export const getGarageVehicle = (state) => state.vehicle.garageVehicle;

export default vehicle;
