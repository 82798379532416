import { configureStore } from "@reduxjs/toolkit";
import { useDispatch, useSelector } from "react-redux";

//Import your reducers here.
import misc from "./reducers/misc";
import cart from "./reducers/cart";
import vehicle from "./reducers/vehicle";
import uiState from "./reducers/uiState";
import customer from "./reducers/customer";
import makeOptions from "./reducers/makeOptions";
import globalAssets from "./reducers/globalAssets";
import miniTrolley from "./reducers/miniTrolley";

export const makeStore = () => {
  return configureStore({
    reducer: {
      [cart.name]: cart.reducer,
      [misc.name]: misc.reducer,
      [uiState.name]: uiState.reducer,
      [vehicle.name]: vehicle.reducer,
      [customer.name]: customer.reducer,
      [makeOptions.name]: makeOptions.reducer,
      [globalAssets.name]: globalAssets.reducer,
      [miniTrolley.name]: miniTrolley.reducer,
    },
    devTools: process.env.NODE_ENV !== "production",
  });
};

// Use throughout your app instead of plain `useDispatch` and `useSelector`
export const useAppDispatch = useDispatch.withTypes();
export const useAppSelector = useSelector.withTypes();
