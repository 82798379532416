import axios from "axios";
import { keys } from "../utils/keys";
import { routes } from "../utils/routes";
import { constants } from "../utils/constants";
import { handleErrorLog } from "../utils/error_logs";
import { handleToastError } from "../utils/functions";

const METADATA = "metaData";
const MODELS_LIST = "modelsList";
const BASE_URL = keys.general.restUrl;

const handleApiResonse = (
  t,
  dataObj,
  defaultRes,
  resType,
  defaultErr,
  field = false,
  manual = false,
) => {
  const err = dataObj?.statusCode || dataObj?.session?.statusCode || defaultErr;
  if (err === constants.service_response.unavailable) {
    handleToastError(t("messages.service_unavailable"));
  } else if (err === constants.service_response.not_found) {
    handleToastError(t("messages.no_results.vehicle"));
  } else if (err === constants.service_response.modal_not_found) {
    handleToastError(t("messages.no_results.models"));
  } else if (resType === MODELS_LIST) {
    defaultRes = dataObj.models.map((m) => ({
      value: m.id,
      type: m.type,
      label: m.title,
    }));
  } else if (resType === METADATA) {
    const modelVersions = dataObj?.metaData?.modelVersions || [];
    const engineSizes = dataObj?.metaData?.engineSizes || [];
    const bodyStyles = dataObj?.metaData?.bodyStyles || [];
    const powers = dataObj?.metaData?.powers || [];
    defaultRes.year = modelVersions.map((y) => ({
      value: y.id,
      label: (y.title.match(/\(([^)]+)\)/) || [])[1] || y.title,
    }));

    switch (field) {
      case "body":
        defaultRes.body = bodyStyles.map((b) => ({
          value: b.id,
          label: b.title,
        }));
        defaultRes.engine = engineSizes.map((e) => ({
          value: e.id,
          label: e.title,
        }));
        defaultRes.kw_hp = powers.map((p) => ({
          value: p.id,
          label: p.title?.replace(/bhp/g, ""),
        }));
        break;
      case "engine":
        defaultRes.body = bodyStyles.map((b) => ({
          value: b.id,
          label: b.title,
        }));
        defaultRes.engine = engineSizes.map((e) => ({
          value: e.id,
          label: e.title,
        }));
        break;
      case "year":
        defaultRes.engine = engineSizes.map((e) => ({
          value: e.id,
          label: e.title,
        }));

        if (!engineSizes.length) {
          defaultRes.search_now = true;
        } else if (
          engineSizes.length === 1 &&
          bodyStyles.length === 1 &&
          powers.length === 1
        ) {
          defaultRes.extra_fields = {
            engine: engineSizes[0].value,
            body: bodyStyles[0].value,
            kw_hp: powers[0].value,
          };
        }
        break;
    }
    if (manual) {
      defaultRes.sessionId = dataObj?.session?.sessionId;
    }
  }
  return defaultRes;
};

export const getManualBreadcrumb = (searchParams, category, resObj) => {
  const makeLabel = searchParams.make;
  const modelLabel = searchParams.model;
  resObj.heading = `${makeLabel} ${modelLabel} ${category.name}`;
  resObj.breadcrumbs = [];
  resObj.breadcrumbs.push({
    title: "Makes/Models",
    url: routes.makesAndModels,
  });
  resObj.breadcrumbs.push({
    title: `${makeLabel} Spare Parts`,
    url: `${routes.manualSelection}?make=${makeLabel}`,
  });
  resObj.breadcrumbs.push({
    title: `${makeLabel} ${modelLabel} Car Parts`,
    url: `${routes.manualSelectionParts}?make=${makeLabel}&model=${modelLabel}`,
  });
  resObj.breadcrumbs.push({ title: resObj.heading });
  return resObj;
};

export const getVehicleManufacturers = async () => {
  const { data = [] } = await axios.get(
    BASE_URL + routes.restApi.manufacturers,
  );
  if (data.length > 0 && data[0]?.manufacturers) {
    return data[0]?.manufacturers
      ?.filter((m) => m.title !== "Pre G:Code Data")
      .map((make) => ({ value: make.id, label: make.title }));
  }
  return [];
};

export const getModelList = async (id, t) => {
  let modelsList = [];
  try {
    const { data = [] } = await axios.get(
      `${BASE_URL + routes.restApi.modellist}/?manufacturerId=${id}`,
    );

    if (data.length > 0) {
      modelsList = handleApiResonse(
        t,
        data[0],
        modelsList,
        MODELS_LIST,
        "ModelNotFound",
      );
    }
  } catch (err) {
    handleErrorLog({
      error: err,
      additional_info: { manufacturerId: id },
      msg: "Error while fetching models list",
    });
  }
  return modelsList;
};

export const getManufacturerModels = async (manufacturerId) => {
  let modelsList = [];
  try {
    const { data = [] } = await axios.get(
      `${BASE_URL + routes.restApi.models}/?manufacturerId=${manufacturerId}`,
    );
    if (data.length > 0 && data[0].models) {
      modelsList = data[0].models.map((m) => ({
        value: m.id,
        type: m.type,
        label: m.title,
      }));
    }
  } catch (err) {
    handleErrorLog({
      error: err,
      additional_info: { manufacturerId },
      msg: "Error while fetching manufacturer models",
    });
  }
  return modelsList;
};

export const getVehicleNextOptions = async (
  t,
  payload,
  currentField,
  hasManualLook = false,
) => {
  let queryArr = [];
  for (const key in payload) {
    let queryKey = constants.vehicle_fields[key];
    if (queryKey && payload.hasOwnProperty(key)) {
      queryArr.push(`${queryKey}=${payload[key]}`);
    }
  }

  if (hasManualLook) {
    queryArr.push("isMvl=1");
  }

  const { data = [] } = await axios.get(
    `${BASE_URL + routes.restApi.vehiclemetadata}/?${queryArr.join("&")}`,
  );

  let resObject = { year: [], engine: [], body: [], kw_hp: [] };
  if (data.length > 0) {
    resObject = handleApiResonse(
      t,
      data[0],
      resObject,
      METADATA,
      "VehicleNotFound",
      currentField,
      hasManualLook,
    );
  }
  return resObject;
};

export const getDetailByLabel = async (queryString) => {
  let response = {};
  try {
    const { data = [] } = await axios.get(
      `${BASE_URL + routes.restApi.makesandmodels}?${queryString}`,
    );

    if (data.length > 0) {
      if (data[0]?.manufacturers) {
        response.manufacturer = {
          label: data[0].manufacturers?.title,
          value: Number(data[0].manufacturers?.id),
        };
      }

      if (data[1] && data[1]?.models) {
        response.model = {
          label: data[1].models?.title,
          value: data[1].models?.id,
        };
      }
    }
  } catch (err) {
    handleErrorLog({
      error: err,
      additional_info: { queryString },
      msg: "Error while fetching manufacturer model by labels",
    });
  }
  return response;
};
