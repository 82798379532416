export const keys = {
  general: {
    restUrl: process.env.NEXT_PUBLIC_REST_URL,
    enableBot: process.env.NEXT_PUBLIC_ENABLE_BOT,
    websiteUrl: process.env.NEXT_PUBLIC_WEBSITE_URL,
    graphqlUrl: process.env.NEXT_PUBLIC_GRAPHQL_URL,
    reviewAPIUrl: process.env.NEXT_PUBLIC_REVIEW_API,
    backendGraphqlUrl: process.env.NEXT_PUBLIC_BACKEND_GRAPHQL_URL,
    addExtension: process.env.NEXT_PUBLIC_APP_PAGE_EXTENSION === "1",
  },
  contentful: {
    env: process.env.CONTENTFUL_ENV,
    spaceId: process.env.CONTENTFUL_SPACE_ID,
    previewHost: process.env.CONTENTFUL_PREVIEW_HOST,
  },
  redis: {
    host: process.env.NEXT_PUBLIC_REDIS_HOST,
    port: process.env.NEXT_PUBLIC_REDIS_PORT,
    expiry: process.env.NEXT_PUBLIC_REDIS_EXIRY_DAY || 1,
  },
};
