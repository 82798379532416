import axios from "axios";
import { keys } from "../utils/keys";
import { apiBaseUrl } from "./backend";

/**
 * @param {{ email: string }} payload
 */
export const subscribeNewsletter = async (payload) => {
  const { email } = payload;
  const axiosRes = await axios.post(keys.general.backendGraphqlUrl, {
    query: `mutation SubscribeEmailToNewsletter($email: String!) {
      subscribeEmailToNewsletter(email: $email) {
        status
      }
    }`,
    variables: {
      email,
    },
  });
  return axiosRes.data;
};

/**
 * For Details on fetching from reviews api.
 *  @see https://developer.reviews.io/reference/899f58c2c4431ec1000ec59a38b2916b
 * */
export const fetchReviews = async () => {
  const searchParamsObj = {
    store: "gsf-car-parts",
    type: "store_review",
    minRating: 5,
    sort: "date_desc",
    include_unpublished_photos: true,
    per_page: 10,
    page: 1,
  };

  const searchParamString = new URLSearchParams(searchParamsObj).toString();

  const res = await fetch(
    `${keys.general.reviewAPIUrl}reviews?${searchParamString}`,
    {
      next: {
        //Revalidate after half a day.
        revalidate: 43200,
      },
    },
  );
  return res.json();
};

export const getContactFormOptions = async () => {
  const axiosRes = await axios.post(keys.general.backendGraphqlUrl, {
    query: `query {
			reasons{
					list
			}
		}`,
  });
  return axiosRes.data;
};

export const sendContactUsForm = async (payload) => {
  const axiosRes = await axios.post(
    keys.general.backendGraphqlUrl,
    {
      query: `mutation {
        contactUs(
          input: {
            comment: """${payload.comment}""",
            email: "${payload.email}",
            telephone: "${payload.telephone}",
            name: "${payload.firstName} ${payload.lastName}",
            reason: "${payload.reason}",
            vehicle_registration: "${payload.vehicle_registration}",
            order_number: "${payload.order_number}" 
          }
        ) {
          status
        }
		  }`,
    },
    {
      headers: {
        "X-ReCaptcha": payload.recaptchaToken,
      },
    },
  );
  return axiosRes.data;
};

/**
 * For Details on fetching from reviews api.
 *  @see https://developer.reviews.io/reference/899f58c2c4431ec1000ec59a38b2916b
 *
 * */
export const fetchProductsReviews = async (productSkus) => {
  try {
    const skuString = productSkus.join(";");
    const res = await fetch(
      `${keys.general.reviewAPIUrl}product/rating-batch?store=gsf-car-parts&sku=${skuString}`,
      {
        method: "GET",
        headers: { accept: "application/json" },
        next: {
          //Revalidate after half a day.
          revalidate: 43200,
        },
      },
    );

    const reviews = await res.json();
    return { reviews, error: null };
  } catch (error) {
    return { reviews: null, error: error.message };
  }
};

export const getGloablAssets = async (onlyStoreConfig = false) => {
  let fields = `
    storeConfig {
      timezone
      contact_mobile_number
      seasonal_product_title
      seasonal_product_desc
      featured_product_title
      featured_product_desc
      popular_category_title
      popular_category_desc
      recentlyview_product_title
      recentlyview_product_desc
    }`;
  if (!onlyStoreConfig) {
    fields += `
      bestseller
      recommended
      currency {
        base_currency_symbol
      }`;
  }
  const query = `query { ${fields} }`;
  const axiosRes = await axios.get(keys.general.backendGraphqlUrl, {
    params: {
      query: query,
    },
    paramsSerializer: {
      indexes: null,
    },
  });
  return axiosRes.data;
};

export const getAssetImages = async () => {
  const query = `
      query {
        bestseller
        recommended
      }
    `;
  const axiosRes = await axios.get(apiBaseUrl(), {
    params: {
      query,
    },
    paramsSerializer: {
      indexes: null,
    },
  });
  return axiosRes.data;
};

export const getAdobeStoreConfig = async () => {
  const query = `
  query GetDataServicesContext {
  dataServicesStorefrontInstanceContext {
    environment_id
    environment    
    instance_id
    store_url
    website_id
    website_code
    store_id
    store_code
    store_view_id
    store_view_code
    website_name
    store_name
    store_view_name
    base_currency_code
    store_view_currency_code
    customer_group
  }
  dataServicesMagentoExtensionContext 
  {    
    magento_extension_version  
  } 
}
  `;
  const axiosRes = await axios.get(apiBaseUrl(), {
    params: {
      query,
    },
    paramsSerializer: {
      indexes: null,
    },
  });
  return axiosRes.data;
};

export const getCustomerGroup = async () => {
  const query = `
  query GetDataServicesContext {
  dataServicesStorefrontInstanceContext {
    customer_group
  }
}
  `;
  const axiosRes = await axios.get(apiBaseUrl(), {
    params: {
      query,
    },
    paramsSerializer: {
      indexes: null,
    },
  });
  return axiosRes.data;
};


