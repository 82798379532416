import Cookies from "js-cookie";
import { constants } from "./constants";

export const updateVehicleCookies = ({ key = "", hasManualLook = false }) => {
  if (typeof window === "undefined") return;
  if (key) {
    Cookies.set(constants.cookies.selectedVehicle, key, {
      expires: 7,
      secure: true,
      sameSite: "strict",
    });
  }
  if (hasManualLook) {
    Cookies.set(constants.cookies.manual_lookup, 1, {
      expires: 1,
      secure: true,
      sameSite: "strict",
    });
  }
  Cookies.set(constants.cookies.new_vehicle, 1, {
    expires: 1,
    secure: true,
    sameSite: "strict",
  });
};
