import axios from "axios";
import Cookies from "js-cookie";
import { keys } from "../utils/keys";
import { apiBaseUrl } from "./backend";
import { cartFragments } from "./fragments";
import { constants } from "../utils/constants";
import { handleErrorLog } from "../utils/error_logs";
import { getCustomerAxios, baseRequest } from "../utils/axios";

export const createCartForGuestUser = async () => {
  const axiosRes = await axios.post(keys.general.backendGraphqlUrl, {
    query: `mutation {
      createGuestCart {
          cart {
              id
          }
      }
    }`,
  });
  return axiosRes.data;
};

export const getCartForLoggedInUser = async () => {
  const customerAxios = await getCustomerAxios();
  const axiosRes = await customerAxios.post(keys.general.backendGraphqlUrl, {
    query: `{
      customerCart{
        id
        email
      }
    }`,
  });
  return axiosRes.data;
};

export const addToTrolley = async ({ cartItems }) => {
  const specialCoupon = Cookies.get(constants.cookies.specialCoupon) || "";
  const cartId = localStorage.getItem(constants.localStorage.cartId);
  const isGuest = !Cookies.get(constants.cookies.customerToken);
  const currentAxios = isGuest ? axios : await getCustomerAxios();
  const axiosRes = await currentAxios.post(keys.general.backendGraphqlUrl, {
    query: `mutation AddToCart($cartId: String!, $cartItems: [CartItemInput!]!, $specialCoupon: String) {
      addProductsToCart(cartId: $cartId, cartItems: $cartItems, specialCoupon: $specialCoupon) {
        cart {
          total_quantity
          is_home_delivery_available
          itemsV2 {
            total_count
            items {
              ...CartItemFields
            }
          }
          ${cartFragments.cartPrice}
        }
        user_errors {
          code
          message
        }
      }
    }
    ${cartFragments.cartItemFields}
    fragment Money on Money {
      value
      currency
    }`,
    variables: {
      cartId,
      cartItems,
      specialCoupon,
    },
  });
  return axiosRes.data;
};

export const getCartItems = async ({ getBranchInfo }) => {
  const cartId = localStorage.getItem(constants.localStorage.cartId);
  const isGuest = !Cookies.get(constants.cookies.customerToken);
  const currentAxios = isGuest ? axios : await getCustomerAxios();

  const axiosRes = await currentAxios.post(keys.general.backendGraphqlUrl, {
    query: `query Cart($cartId: String!) {
      cart(cart_id: $cartId) {
       
        ${
          getBranchInfo
            ? `branch {
                hyperlink
                type
                branch_id
                store_id
                name
                 address1
        address2
        address3
        address4
        area
      distance
        postcode
        latitude
        telephone
        longitude
        hyperlink
        email
         is_hub
        opening_mon_fri
        closing_mon_fri
        open_saturday
        opening_sat
        closing_sat
        open_sunday
        opening_sun
        closing_sun
         holiday_info
        {date}
      
              }`
            : ""
        }
        email
        clickandcollect_branch_id
        is_home_delivery_available
        total_quantity
        itemsV2 {
          total_count
          items {
            ...CartItemFields
          }
        }
        billing_address {
          ...AddressFields
        }
        shipping_addresses {
          ...AddressFields
          ...ShippingAddress
        }
        applied_coupons {
          code
        }
        ${cartFragments.cartPrice}
      }
    }
    ${cartFragments.cartItemFields}
    ${cartFragments.addressFields}
    ${cartFragments.shippingAddress}
    fragment Money on Money {
      value
      currency
    }`,
    variables: {
      cartId,
    },
  });
  return axiosRes.data;
};

export const mergeCarts = async ({ guestCartId, getBranchInfo }) => {
  const customerAxios = await getCustomerAxios();
  const axiosRes = await customerAxios.post(keys.general.backendGraphqlUrl, {
    query: `mutation MergeCarts(
      $sourceCartId: String!
    ) {
      mergeCarts(
        source_cart_id: $sourceCartId
      ) {
        billing_address {
          ...AddressFields
        }
        shipping_addresses {
          ...AddressFields
          ...ShippingAddress
        }
        ${
          getBranchInfo
            ? `branch {
                hyperlink
                type
                branch_id
                store_id
                name
                 address1
        address2
        address3
        address4
        area
      distance
        postcode
        latitude
        telephone
        longitude
        hyperlink
        email
         is_hub
        opening_mon_fri
        closing_mon_fri
        open_saturday
        opening_sat
        closing_sat
        open_sunday
        opening_sun
        closing_sun
         holiday_info
        {date}
      
              }`
            : ""
        }
        email
        clickandcollect_branch_id
        is_home_delivery_available
        total_quantity
        id
        itemsV2 {
          total_count
          items {
            ...CartItemFields
          }
        }
        applied_coupons {
          code
        }
        ${cartFragments.cartPrice}
      }
    }
    ${cartFragments.cartItemFields}
    ${cartFragments.addressFields}
    ${cartFragments.shippingAddress}
    fragment Money on Money {
      value
      currency
    }`,
    variables: {
      sourceCartId: guestCartId,
    },
  });

  return axiosRes.data;
};

export const removeItemFromCart = async ({ cartItemId, cartId = null }) => {
  const userCartId = cartId
    ? cartId
    : localStorage.getItem(constants.localStorage.cartId);

  const isGuest = !Cookies.get(constants.cookies.customerToken);
  const currentAxios = isGuest ? axios : await getCustomerAxios();
  const axiosRes = await currentAxios.post(keys.general.backendGraphqlUrl, {
    query: `mutation RemoveItemFromCart($input: RemoveItemFromCartInput!) {
      removeItemFromCart(input: $input) {
        cart {
          total_quantity
          is_home_delivery_available
          itemsV2 {
            total_count
             items {
              ...CartItemFields
            }
          }
          ${cartFragments.cartPrice}
          }
      }
    }
    
     ${cartFragments.cartItemFields}


    fragment Money on Money {
      value
      currency
    }`,
    variables: {
      input: {
        cart_id: userCartId,
        cart_item_uid: cartItemId,
      },
    },
  });
  return axiosRes.data;
};

export const removeOutOfStockProductsFromCart = async ({ cartItemIds }) => {
  const cartId = localStorage.getItem(constants.localStorage.cartId);
  const isGuest = !Cookies.get(constants.cookies.customerToken);
  const currentAxios = isGuest ? axios : await getCustomerAxios();
  const axiosRes = await currentAxios.post(keys.general.backendGraphqlUrl, {
    query: `mutation RemoveItemFromCart($input: RemoveItemsFromCartInput!) {
      removeItemsFromCart(input: $input) {
        cart {
          total_quantity
          is_home_delivery_available
          itemsV2 {
            total_count
             items {
              ...CartItemFields
            }
          }
          ${cartFragments.cartPrice}
        }
      }
    }
         ${cartFragments.cartItemFields}
    
    fragment Money on Money {
      value
      currency
    }`,

    variables: {
      input: {
        cart_id: cartId,
        cart_item_uids: cartItemIds,
      },
    },
  });
  return axiosRes.data;
};

export const updateItemQuantity = async ({ cartItems }) => {
  const cartId = localStorage.getItem(constants.localStorage.cartId);
  const isGuest = !Cookies.get(constants.cookies.customerToken);
  const currentAxios = isGuest ? axios : await getCustomerAxios();
  const axiosRes = await currentAxios.post(keys.general.backendGraphqlUrl, {
    query: `mutation UpdateCartItems($input: UpdateCartItemsInput) {
      updateCartItems(input: $input) {
        cart {
          total_quantity
          is_home_delivery_available
          itemsV2 {
            total_count
            items {
              ...CartItemFields
            }
          }
          ${cartFragments.cartPrice}
        }
      }
    }
    ${cartFragments.cartItemFields}
    fragment Money on Money {
      value
      currency
    }`,
    variables: {
      input: {
        cart_id: cartId,
        cart_items: cartItems,
      },
    },
  });
  return axiosRes.data;
};

export const applyCouponToCart = async ({ couponCode }) => {
  const cartId = localStorage.getItem(constants.localStorage.cartId);
  const isGuest = !Cookies.get(constants.cookies.customerToken);
  const currentAxios = isGuest ? axios : await getCustomerAxios();
  const axiosRes = await currentAxios.post(keys.general.backendGraphqlUrl, {
    query: `mutation ApplyCouponToCart($input: ApplyCouponToCartInput!) {
      applyCouponToCart(input: $input) {
        cart {
          total_quantity
          is_home_delivery_available
          itemsV2{
            total_count,
            ...ItemsV2
          }
          applied_coupons {
            code
          }
          ${cartFragments.cartPrice}
        }
      }
    }
    fragment ItemsV2 on CartItems {
      items {
        ...CartItemFields
      }
    }
    ${cartFragments.cartItemFields}
    fragment Money on Money {
      value
      currency
    }`,
    variables: {
      input: {
        cart_id: cartId,
        coupon_code: couponCode,
      },
    },
  });
  return axiosRes.data;
};

export const removeCouponFromCart = async () => {
  const cartId = localStorage.getItem(constants.localStorage.cartId);
  const isGuest = !Cookies.get(constants.cookies.customerToken);
  const currentAxios = isGuest ? axios : await getCustomerAxios();
  const axiosRes = await currentAxios.post(keys.general.backendGraphqlUrl, {
    query: `mutation RemoveCouponFromCart($input: RemoveCouponFromCartInput!) {
      removeCouponFromCart(input: $input) {
        cart {
          is_home_delivery_available
          total_quantity
          itemsV2{
            total_count,
            ...ItemsV2
          }
          applied_coupons {
            code
          }
          ${cartFragments.cartPrice}
        }
      }
    }
    fragment ItemsV2 on CartItems {
      items {
        ...CartItemFields
      }
    }
    ${cartFragments.cartItemFields}
    fragment Money on Money {
      value
      currency
    }`,
    variables: {
      input: {
        cart_id: cartId,
      },
    },
  });
  return axiosRes.data;
};

export const getYouMayLikeProducts = async ({
  pageSize,
  order,
  specialCoupon,
}) => {
  const isGuest = !Cookies.get(constants.cookies.customerToken);
  const currentAxios = isGuest ? axios : await getCustomerAxios();

  const variables = JSON.stringify({
    productFilter: {
      show_on_basket: {
        eq: 1,
      },
      price: { from: 0.01 },
    },
    productSort: {
      name: order,
    },
    pageSize: pageSize,
  });

  const query = `
    query showOnBasketProducts(
      $productFilter: ProductAttributeFilterInput
      $productSort: ProductAttributeSortInput
      $pageSize: Int
    ) {
      products: products(
        filter: $productFilter
        sort: $productSort
        pageSize: $pageSize
        specialCoupon: "${specialCoupon || ""}"
      ) {
        total_count
        items {
          ...Products
        }
      }
    }
    ${cartFragments.ProductInterface}
  `;

  const axiosRes = await currentAxios.get(keys.general.backendGraphqlUrl, {
    params: {
      query,
      variables,
    },
    paramsSerializer: {
      indexes: null, // Ensures proper serialization
    },
  });

  return axiosRes.data;
};

export const setShippingAddressToCart = async ({ formData, addressId }) => {
  const cart_id = localStorage.getItem(constants.localStorage.cartId);
  const shipping_address = {};
  if (addressId) {
    shipping_address.customer_address_id = addressId;
  } else {
    const { county, quickAddress, ...rest } = formData;
    shipping_address.address = {
      region: county,
      country_code: "GB",
      save_in_address_book: true,
      ...rest,
    };
  }
  const payload = {
    cart_id,
    shipping_addresses: [shipping_address],
  };

  const isGuest = !Cookies.get(constants.cookies.customerToken);
  const currentAxios = isGuest ? axios : await getCustomerAxios();

  const axiosRes = await currentAxios.post(keys.general.backendGraphqlUrl, {
    query: `mutation ($input: SetShippingAddressesOnCartInput!) {
      setShippingAddressesOnCart(input: $input) {
        cart {
          billing_address {
            ...AddressFields
          }
          shipping_addresses {
          ...AddressFields
          ...ShippingAddress
          }
          email
          clickandcollect_branch_id
          is_home_delivery_available
          total_quantity
          itemsV2 {
            total_count
            items {
              ...CartItemFields
            }
          }
          applied_coupons {
            code
          }
          ${cartFragments.cartPrice}
        }
      }
    }
    ${cartFragments.cartItemFields}
    ${cartFragments.addressFields}
    ${cartFragments.shippingAddress}
    fragment Money on Money {
      value
      currency
    }`,
    variables: {
      input: {
        ...payload,
      },
    },
  });
  return axiosRes.data;
};

export const setBillingAddressToCart = async ({
  formData,
  addressId,
  same_as_shipping,
}) => {
  const cart_id = localStorage.getItem(constants.localStorage.cartId);
  const billing_address = {};
  if (addressId) {
    billing_address.customer_address_id = addressId;
  } else if (same_as_shipping) {
    billing_address.same_as_shipping = true;
  } else {
    const { county, quickAddress, ...rest } = formData;
    billing_address.address = {
      region: county,
      country_code: "GB",
      save_in_address_book: true,
      ...rest,
    };
  }

  const payload = {
    cart_id,
    billing_address,
  };

  const isGuest = !Cookies.get(constants.cookies.customerToken);
  const currentAxios = isGuest ? axios : await getCustomerAxios();

  const axiosRes = await currentAxios.post(keys.general.backendGraphqlUrl, {
    query: `mutation ($input: SetBillingAddressOnCartInput!) {
      setBillingAddressOnCart(input: $input) {
        cart {
          billing_address {
            ...AddressFields
          }
          shipping_addresses {
          ...AddressFields
          ...ShippingAddress
          }
          email
          clickandcollect_branch_id
          is_home_delivery_available
          total_quantity
          itemsV2 {
            total_count
            items {
              ...CartItemFields
            }
          }
          applied_coupons {
            code
          }
          ${cartFragments.cartPrice}
        }
      }
    }
    ${cartFragments.cartItemFields}
    ${cartFragments.addressFields}
    ${cartFragments.shippingAddress}
    fragment Money on Money {
      value
      currency
    }`,
    variables: {
      input: {
        ...payload,
      },
    },
  });
  return axiosRes.data;
};

export const setShippingMethodOnCart = async ({
  carrier_code,
  method_code,
}) => {
  const cart_id = localStorage.getItem(constants.localStorage.cartId);
  const isGuest = !Cookies.get(constants.cookies.customerToken);
  const currentAxios = isGuest ? axios : await getCustomerAxios();
  const axiosRes = await currentAxios.post(keys.general.backendGraphqlUrl, {
    query: `mutation SetShippingMethodsOnCart($input: SetShippingMethodsOnCartInput) {
      setShippingMethodsOnCart(input: $input) {
        cart {
          shipping_addresses {
          ...AddressFields
          ...ShippingAddress
          }
          ${cartFragments.cartPrice}
        }
      }
    }
    ${cartFragments.addressFields}
    ${cartFragments.shippingAddress}
    fragment Money on Money {
      value
      currency
    }`,
    variables: {
      input: {
        cart_id,
        shipping_methods: [
          {
            carrier_code,
            method_code,
          },
        ],
      },
    },
  });
  return axiosRes.data;
};

export const resetCartAddress = async (type) => {
  const cart_id = localStorage.getItem(constants.localStorage.cartId);
  const isGuest = !Cookies.get(constants.cookies.customerToken);
  const currentAxios = isGuest ? axios : await getCustomerAxios();
  const axiosRes = await currentAxios.post(keys.general.backendGraphqlUrl, {
    query: `mutation ResetAddress($cart_id: String!, $type: AddressTypeEnum) {
      resetAddress(cart_id: $cart_id, type: $type) {
        billing_address {
          ...AddressFields
        }
        shipping_addresses {
          ...AddressFields
          ...ShippingAddress
        }
        email
        clickandcollect_branch_id
        is_home_delivery_available
        total_quantity
        itemsV2 {
          total_count
          items {
            ...CartItemFields
          }
        }
        applied_coupons {
          code
        }
        ${cartFragments.cartPrice}
      }
    }
    ${cartFragments.cartItemFields}
    ${cartFragments.addressFields}
    ${cartFragments.shippingAddress}
    fragment Money on Money {
      value
      currency
    }`,
    variables: {
      cart_id,
      type,
    },
  });
  return axiosRes.data;
};

export const setCnCBranch = async ({ branchId, setPreferred = false }) => {
  const cart_id = localStorage.getItem(constants.localStorage.cartId);
  const isGuest = !Cookies.get(constants.cookies.customerToken);
  const currentAxios = isGuest ? axios : await getCustomerAxios();
  const axiosRes = await currentAxios.post(keys.general.backendGraphqlUrl, {
    query: `mutation SetCollectBranch($input: SetCollectBranchInput!) {
      setCollectBranch(input: $input) {
        billing_address {
          ...AddressFields
        }
        shipping_addresses {
          ...AddressFields
          ...ShippingAddress
        }
        email
        clickandcollect_branch_id
        total_quantity
        itemsV2 {
          total_count
          items {
            ...CartItemFields
          }
        }
        applied_coupons {
          code
        }
        ${cartFragments.cartPrice}
      }
    }
    ${cartFragments.cartItemFields}
    ${cartFragments.addressFields}
    ${cartFragments.shippingAddress}
    fragment Money on Money {
      value
      currency
    }`,
    variables: {
      input: {
        cart_id,
        branch_id: branchId,
        store_page: setPreferred,
      },
    },
  });
  return axiosRes.data;
};

export const resetCnCBranch = async () => {
  const cart_id = localStorage.getItem(constants.localStorage.cartId);
  const isGuest = !Cookies.get(constants.cookies.customerToken);
  const currentAxios = isGuest ? axios : await getCustomerAxios();
  const axiosRes = await currentAxios.post(keys.general.backendGraphqlUrl, {
    query: `mutation SetCollectBranch($cart_id: String!) {
      resetCollectBranch(cart_id: $cart_id) {
        shipping_addresses {
          ...AddressFields
          ...ShippingAddress
        }
        email
        clickandcollect_branch_id
        total_quantity
        itemsV2 {
          total_count
          items {
            ...CartItemFields
          }
        }
        applied_coupons {
          code
        }
        ${cartFragments.cartPrice}
      }
    }
    ${cartFragments.cartItemFields}
    ${cartFragments.addressFields}
    ${cartFragments.shippingAddressWithoutMethod}
    fragment Money on Money {
      value
      currency
    }`,
    variables: {
      cart_id,
    },
  });
  return axiosRes.data;
};

export const getClickAndCollectStock = async ({ branchId }) => {
  const cart_id = localStorage.getItem(constants.localStorage.cartId);
  const isGuest = !Cookies.get(constants.cookies.customerToken);
  const currentAxios = isGuest ? axios : await getCustomerAxios();
  const axiosRes = await currentAxios.post(keys.general.backendGraphqlUrl, {
    query: `query GetClickAndCollectStock($input: ClickAndCollectStockInput!) {
      clickAndCollectStock(input: $input) {
        is_cnc_available
        product_stock {
          sku
          in_stock
          stock_qty
          uid
        }
      }
    }`,
    variables: {
      input: {
        cart_id,
        branch_id: branchId,
      },
    },
  });
  return axiosRes.data;
};

// get order confirmation
export const getOrderConfirmation = ({ token }) => {
  return baseRequest(
    apiBaseUrl(),
    `query GuestOrderByToken($input:OrderTokenInput!){
      guestOrderByToken(
        input: $input
      ) {
        id
        email
        order_number
        shipping_method
         branch{
            branch_id
            name
            address1
            address2
            address3
            address4
            postcode
            telephone
            email
            distance
            opening_mon_fri
            closing_mon_fri
            open_saturday
            opening_sat
            closing_sat
            open_sunday
            opening_sun
            closing_sun
        }
        payment_methods{
       name
       type
       additional_data{
           name
           value
       }
    }
        items {
          product_name
          product_sku
          isFreegift
          product {
            image {
              url
              label
            }
            sold_in_pairs
            extraVariable {
              brand_info {
                id
                title
                image
              }
            }
          }
          quantity_ordered
          product_sale_price {
            value
            currency
          }
        }
        order_date
        shipping_address {
          firstname
          lastname
          middlename
          region
          region_id
          country_code
          street
          company
          telephone
          fax
          postcode
          city
        }
        billing_address {
          firstname
          lastname
          middlename
          region
          region_id
          country_code
          street
          company
          telephone
          fax
          postcode
          city
        }
        total {
        discounts{
                        amount { value currency }
                    }
          subtotal_incl_tax {
            value
            currency
          }
          gift_promo_discount
          total_tax {
            value
            currency
          }
          taxes {
            amount {
              value
              currency
            }
            title
            rate
          }
          grand_total {
            value
            currency
          }
          total_shipping {
            value
            currency
          }
          shipping_handling {
            total_amount {
              value
              currency
            }
            amount_including_tax {
              value
              currency
            }
            amount_excluding_tax {
              value
              currency
            }
          }
        }
        status
      }
    }`,
    {
      input: {
        token,
      },
    },
  );
};

export const getDefaultAutoApplyCouponCode = async () => {
  const isGuest = !Cookies.get(constants.cookies.customerToken);
  const currentAxios = isGuest ? axios : await getCustomerAxios();
  try {
    const axiosRes = await currentAxios.post(keys.general.backendGraphqlUrl, {
      query: `
        query {
          getDefaultAutoApplyCouponCode
        }
      `,
    });
    return axiosRes.data;
  } catch (error) {
    handleErrorLog({
      error,
      additional_info: { isGuest },
      msg: "Error while fetching default auto-apply coupon code",
    });
    throw error;
  }
};

export const getActiveBanner = async (tokenCipher) => {
  const axiosClient = tokenCipher ? await getCustomerAxios(tokenCipher) : axios;
  try {
    const axiosRes = await axiosClient.post(apiBaseUrl(), {
      query: `query ActiveSmartBanner {
        activeSmartBanner {
          text
          terms
          to_date
          entity_id
          bkg_colour
          font_colour
          coupon_code
          button_text
          border_colour
          end_date_time
          countdown_time
          start_date_time
          cookies_lifetime
          countdown_status
          auto_apply_button
        }
      }`,
    });
    return axiosRes.data;
  } catch (err) {
    handleErrorLog({
      error: err,
      additional_info: { tokenCipher },
      msg: "Error while fetching smart banner coupon",
    });
    throw err;
  }
};
